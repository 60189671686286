import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Signup = React.lazy(() => import('./views/pages/signup'));
const Login = React.lazy(() => import('./views/pages/login'));
const Page401 = React.lazy(() => import('./views/pages/page401'));
const Page404 = React.lazy(() => import('./views/pages/page404'));
const Page500 = React.lazy(() => import('./views/pages/page500'));
const Page501 = React.lazy(() => import('./views/pages/page501'));
const Website = React.lazy(() => import('./views/embed/website'));
const EmbedApplication = React.lazy(() => import('./views/embed/application'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path='/signup' name='Sign-up' render={props => <Signup {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/401" name="Page 401" render={props => <Page401 {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/501" name="Page 501" render={props => <Page501 {...props} />} />
            <Route exact path={'/embed-application/:id(\\d+)'} name='Embed Apllication' render={props => <EmbedApplication {...props} />} />
            <Route path='/embed' name='website' render={props => <Website {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
